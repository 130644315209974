import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../firebase-config";

import { addDoc, collection } from "firebase/firestore";

const WriteAPrompt = () => {
  const [prompt, setPrompt] = useState();

  const postsCollectionRef = collection(db, "Prompts");
  let navigate = useNavigate();

  const createPrompt = async (prompt) => {
    await addDoc(postsCollectionRef, {
      Prompt: prompt,
      YesCounter: 0,
      NoCounter: 0,
    });
    navigate("/");
  };

  return (
    <div className="container">
      <div className="container box">
        <h1>Write A Prompt</h1>
        <div className="">
          <h3>Is It Wrong...</h3>
          <textarea
            className="input-group"
            placeholder="To set up all of my friends' computers to mine crypto for me when they are not using them?"
            onChange={(event) => {
              setPrompt(event.target.value);
            }}
          />
        </div>
        <div
          className="input-group btn btn-primary mt-3"
          onClick={() => {
            createPrompt(prompt);
          }}
        >
          Submit Prompt For Review
        </div>

        <div>
          <Link to="/" className="btn">
            Go Back
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WriteAPrompt;
