// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAwV00vBzj1z0q9rOSOjvIrNsPBs6xewVk",
  authDomain: "is-it-wrong.firebaseapp.com",
  projectId: "is-it-wrong",
  storageBucket: "is-it-wrong.appspot.com",
  messagingSenderId: "761271314150",
  appId: "1:761271314150:web:7625cd3a4caa1324b03d27",
  measurementId: "G-BVB6WBJPEZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
