import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { auth, db } from "../firebase-config";
import {
  getDocs,
  collection,
  doc,
  updateDoc,
  increment,
} from "firebase/firestore";

const Main = () => {
  const [promptList, setPromptList] = useState([]);
  const [queue, setQueue] = useState(0);
  const [answered, setAnswered] = useState(false);

  const postsCollectionRef = collection(db, "Prompts");

  useEffect(() => {
    const getPrompts = async () => {
      const data = await getDocs(postsCollectionRef);
      setPromptList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getPrompts();
    const loG = async () => {};
    loG();
  }, []);

  // const getPrompts = async () => {
  //   const data = await getDoc(postsCollectionRef);
  //   setPromptList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  // };
  // console.log(promptList);

  const Prompt = () => {
    let item = promptList.map((prompt) => {
      return prompt.Prompt;
    });
    console.log(item);
    return (
      <div>
        <p className="Prompt-P">
          {/* {promptList.map((prompt) => {
            return prompt.Prompt;
          })} */}
          {item[queue] ? item[queue] : "no more prompts"}
        </p>
      </div>
    );
  };

  const increment = async (counterType) => {
    console.log(promptList);
    let docRef = doc(db, "Prompts", promptList[queue].id);
    console.log(promptList[queue][counterType]);
    await updateDoc(docRef, {
      [counterType]: promptList[queue][counterType] + 1,
    });
    //setQueue(queue + 1);
    setAnswered(true);
  };

  const Score = ({ counterType, type }) => {
    let num = promptList[queue];
    console.log(num, promptList[queue][counterType]);
    return (
      <div className={`${type}Button col-5`}>
        {promptList[queue][counterType]}
      </div>
    );
  };

  return (
    <div className="container box justify-content-center">
      <div className="text-start">
        <Link to="WriteAPrompt" className="btn text-light">
          Write a Prompt
        </Link>
      </div>

      <div className="container content-box d-flex justify-content-center align-items-center">
        <div className="row justify-content-evenly">
          <div className="Prompt-Box">
            <h2 className="Prompt-Header">Is It Wrong...</h2>
            <Prompt />
          </div>

          {!answered ? (
            <div className="row justify-content-evenly">
              <div
                className="NoButton"
                onClick={() => {
                  increment("NoCounter");
                  //increment("NoCounter");
                }}
              >
                No
              </div>
              <div
                className="YesButton"
                onClick={() => {
                  increment("YesCounter");
                  //increment("NoCounter");
                }}
              >
                Yes
              </div>
            </div>
          ) : (
            <div className="row justify-content-evenly">
              <Score counterType={"NoCounter"} type={"No"} />
              <Score counterType={"YesCounter"} type={"Yes"} />
            </div>
          )}
          {/* <div
            className="button btn btn-danger col-5"
            onClick={() => {
              increment("NoCounter");
              //increment("NoCounter");
            }}
          >
            No
          </div>
          <div
            className="button btn btn-success col-5"
            onClick={() => {
              increment("YesCounter");
              //increment("NoCounter");
            }}
          >
            Yes
          </div> */}
          <div
            className="NextButton col-9 mt-5"
            onClick={() => {
              setAnswered(false);
              setQueue(queue + 1);
            }}
          >
            Next
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
