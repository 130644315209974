// import logo from "./logo.svg";
import React from "react";
import "./App.css";
import Main from "./pages/Main";
import WriteAPrompt from "./pages/WriteAPrompt";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  //  <div className="App m-3">
  // <Main />
  // </div>

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route index element={<Main />} />
          <Route path="WriteAPrompt" element={<WriteAPrompt />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
